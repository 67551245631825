<template>
  <v-app>
    <!-- <keep-alive> -->
    <component :is="getThemeMode.layout" />
    <!-- </keep-alive> -->

    <v-snackbar v-model="show">
      {{ message }}

      <template v-slot:action>
        <v-btn text @click="show = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      layoutName: "",
      show: false,
      message: ""
    };
  },
  computed: {
    ...mapGetters(["getThemeMode", "getSnackbar", "isAdminUser"])
  },
  created() {
    this.$store.watch(
      state => state.snackbar.snack,
      () => {
        if (this.getSnackbar) {
          this.show = true;
          this.message = this.getSnackbar;
          this.showSnackbar("");
        }
      }
    );
  },
  // mounted() {
  //   if (this.$route.params.layout) {
  //     this.changeThemeLayout(this.$route.params.layout);
  //   }
  // },
  methods: {
    ...mapActions(["changeThemeLayout", "showSnackbar"])
  }
};
</script>
